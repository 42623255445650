import React, { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Image } from '@chatterbug/aaron'
import ProgressCard from 'src/components/ProgressCard/ProgressCard'
import PageContainer from 'src/components/PageContainer/PageContainer'
import FadeIn from 'src/components/FadeIn/FadeIn'
import { useLocale } from 'src/lib/I18nContext'
import { SiteLocale } from 'src/types'

import cardsEn from './assets/cards-en@2x.png'
import cardsDe from './assets/cards-de@2x.png'
import cardsEs from './assets/cards-es@2x.png'
import readingEn from './assets/reading-en@2x.png'
import readingDe from './assets/reading-de@2x.png'
import readingEs from './assets/reading-es@2x.png'
import mediaEn from './assets/media-en@2x.png'
import mediaDe from './assets/media-de@2x.png'
import lessonEn from './assets/lesson-en@2x.png'
import lessonDe from './assets/lesson-de@2x.png'
import writingEn from './assets/writing-en@2x.png'
import writingDe from './assets/writing-de@2x.png'

const getAssets = (locale: SiteLocale) => {
  if (locale === SiteLocale.en) {
    return [cardsEn, readingEn, mediaEn, writingEn, lessonEn]
  }

  if (locale === SiteLocale.de) {
    return [cardsDe, readingDe, mediaDe, writingDe, lessonDe]
  }

  return [cardsEs, readingEs, mediaDe, writingDe, lessonDe]
}

const getMethodLabelId = (index: number): string => `our-method-label-${index}`

const MODES = {
  auto: 'auto',
  manual: 'manual',
}

const OurMethod: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [mode, setMode] = useState(MODES.auto)
  const t = useTranslate()
  const locale = useLocale()

  const labels = [
    {
      heading: t('method.flash.title'),
      description: t('method.flash.description'),
    },
    {
      heading: t('method.reading.title'),
      description: t('method.reading.description'),
    },
    {
      heading: t('method.listening.title'),
      description: t('method.listening.description'),
    },
    {
      heading: t('method.writing.title'),
      description: t('method.writing.description'),
    },
    {
      heading: t('method.speaking.title'),
      description: t('method.speaking.description'),
    },
  ]

  const assets = getAssets(locale)
  const sectionLabelId = 'our-methods-section'

  const handleNext = (index: number, options?: { manual: boolean }) => {
    setCurrentIndex(index)

    if (options?.manual) {
      setMode(MODES.manual)
    }
  }

  return (
    <PageContainer>
      <Box
        as="p"
        variant="text.buttonTitle"
        textAlign="center"
        mx="auto"
        width={`${(10 * 100) / 12}%`}
        mb="3x"
        color="gray46"
        fontSize={12}
        id={sectionLabelId}
      >
        {t('Reading, Writing, Listening, Speaking all in one app')}
      </Box>
      <Box
        as="ul"
        aria-labelledby={sectionLabelId}
        sx={{ listStyle: 'none', padding: 0 }}
      >
        {assets.map((asset, index) => {
          const isCurrent = index === currentIndex
          const label = labels[index]

          return (
            <Box
              as="li"
              key={`our-method-${index}`}
              sx={{ display: isCurrent ? 'block' : 'none' }}
              aria-hidden={!isCurrent}
            >
              <Box
                as="article"
                className="cb-method"
                aria-labelledby={getMethodLabelId(index)}
              >
                <FadeIn
                  key={`method-image-${index}-${currentIndex}`}
                  mx="auto"
                  width={{
                    _: '100%',
                    mobile: `${(10 * 100) / 12}%`,
                    tablet: '100%',
                    tabletWide: `${(10 * 100) / 12}%`,
                    laptop: `${(8 * 100) / 12}%`,
                  }}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Box pt={`${(1078 * 100) / 1646}%`}>
                    {/* vertical space holder */}
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                    }}
                  >
                    <Image
                      src={asset}
                      width="100%"
                      alt={label.heading}
                      loading="lazy"
                    />
                  </Box>
                </FadeIn>
                <Box
                  mt={{
                    _: '-24px',
                    tablet: '-80px',
                  }}
                  mx="auto"
                  width={{
                    _: '100%',
                    tablet: `${(8 * 100) / 12}%`,
                    tabletWide: `${(6 * 100) / 12}%`,
                    laptop: `${(5 * 100) / 12}%`,
                  }}
                  minHeight={{ _: 340, tablet: 290 }}
                >
                  <ProgressCard
                    currentIndex={currentIndex}
                    max={labels.length}
                    animated={mode === MODES.auto}
                    onNext={handleNext}
                  >
                    <FadeIn key={`method-info-${index}-${currentIndex}`}>
                      <Box variant="text.h4" id={getMethodLabelId(index)}>
                        {label.heading}
                      </Box>
                      <Box variant="text.paragraph" mt="1x">
                        {label.description}
                      </Box>
                    </FadeIn>
                  </ProgressCard>
                </Box>
              </Box>
            </Box>
          )
        })}
      </Box>
    </PageContainer>
  )
}

export default OurMethod
