import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex, Grid } from '@chatterbug/aaron'
import Avatar from 'src/components/Avatar/Avatar'
import Quote from 'src/components/Quote/Quote'
import Flag from 'src/components/Flag/Flag'
import { getLocalizedLanguageLabel } from 'src/lib/localization'
import { LanguageCode, CefrLevel } from 'src/types'

export type Props = {
  image: string
  fullName: string
  l2Code: LanguageCode
  hours: number
  cefrLevel: CefrLevel
  testimonial: string
}

const Testimonial: React.FC<Props> = ({
  image,
  fullName,
  l2Code,
  cefrLevel,
  hours,
  testimonial,
  children,
}) => {
  const t = useTranslate()

  return (
    <Box
      p={{
        _: '48px 32px',
        tablet: '48px',
      }}
      sx={{
        borderRadius: '24px',
        overflow: 'hidden',
        backgroundColor: 'white100',
        boxShadow:
          '0px 14.6986px 29.3973px rgba(0, 0, 0, 0.03), 0px 7.34932px 14.6986px rgba(0, 0, 0, 0.03), 0px 3.67466px 7.34932px rgba(0, 0, 0, 0.03), 0px 1.83733px 3.67466px rgba(0, 0, 0, 0.03)',
      }}
    >
      <Grid
        columns={{
          _: '1fr',
          tablet: 'auto 1fr',
        }}
        gap="2x"
      >
        <Flex justifyContent="center" alignItems="flex-start">
          <Avatar
            image={image}
            width={{
              _: '100%',
              tablet: 250,
            }}
            maxWidth={250}
            altText={fullName}
          />
        </Flex>
        <Flex flexDirection="column" alignItems="stretch">
          <Quote text={testimonial} author={fullName} />
          <Flex
            flexDirection="column"
            flexGrow={1}
            alignItems="stretch"
            justifyContent="space-between"
            mt="2x"
            ml={{ _: 0, tablet: 50 }}
          >
            <Flex>
              <Flag code={l2Code} />
              <Box ml="1x" variant="text.paragraph" color="gray46">
                {t(
                  'Took %{hours} hours of %{l2Name} lessons and reached %{cefrLevel}',
                  {
                    hours,
                    cefrLevel: cefrLevel.toUpperCase(),
                    l2Name: getLocalizedLanguageLabel(l2Code, t),
                  }
                )}
              </Box>
            </Flex>
            {children}
          </Flex>
        </Flex>
      </Grid>
    </Box>
  )
}

export default Testimonial
