import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import PageContainer from 'src/components/PageContainer/PageContainer'
import ActionCard, { BUTTON_WIDTH } from 'src/components/ActionCard/ActionCard'
import ButtonSignUp from 'src/sections/ButtonSignUp/ButtonSignUp'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import { LanguageCode, LessonsNavigationItemName } from 'src/types'
import { getVideoTitle, getVideoUrl } from 'src/lib/videos'

import background from './assets/background.svg'
import Video from 'src/components/Video/Video'
import { useLocale } from 'src/lib/I18nContext'

export type Props = {
  l2Code?: LanguageCode | null
}

const SeeInAction: React.FC<Props> = ({ l2Code }) => {
  const t = useTranslate()
  const locale = useLocale()
  const title = getVideoTitle('lessons', t)
  return (
    <FullWidthBackground image={background} width={1440} height={872}>
      <PageContainer>
        <Box
          pt="2x"
          pb={96}
          sx={{
            position: 'relative',
          }}
        >
          <Flex flexDirection="column" alingItems="center">
            <SectionSeparator
              name={LessonsNavigationItemName.inAction}
              color="white100"
            />
            <Box
              variant="text.paragraph"
              mt="5x"
              textAlign="center"
              color="gray88"
            >
              {title}
            </Box>
            <Flex mt="5x" justifyContent="center">
              <Box
                width={{
                  _: '100%',
                  tabletWide: '760px',
                }}
              >
                <Video
                  title={title}
                  url={getVideoUrl('lessons', locale)}
                  className="cb-in-action-video"
                />
              </Box>
            </Flex>
          </Flex>
          <Box
            mt={{
              _: -50,
              tablet: -26,
            }}
            sx={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
            }}
          >
            <ActionCard
              message={t('Start learning a language <strong>Today</strong>')}
              customButton={
                <ButtonSignUp
                  size="large"
                  l2Code={l2Code}
                  width={BUTTON_WIDTH}
                />
              }
            />
          </Box>
        </Box>
      </PageContainer>
    </FullWidthBackground>
  )
}

export default SeeInAction
