import React from 'react'
import { Box, Grid, Image } from '@chatterbug/aaron'

import quote from './assets/quote.svg'

export type Props = {
  text: string
  author?: string
}

const Quote: React.FC<Props> = ({ text, author }) => {
  return (
    <Grid
      columns={{
        _: '1fr',
        tablet: 'auto 1fr',
      }}
      gridGap={{
        _: '1x',
        tablet: '2x',
      }}
    >
      <Image
        src={quote}
        width={32}
        height={22}
        alt={'"'}
        mt={{ _: 0, tablet: '10px' }}
        loading="lazy"
      />
      <Box>
        <Box
          variant="text.paragraph"
          sx={{
            fontSize: 24,
            lineHeight: 1.6,
          }}
        >
          {text}
        </Box>
        {author && (
          <Box
            variant="text.paragraph"
            color="black0"
            sx={{
              fontWeight: 'semiBold',
              fontSize: 16,
              lineHeight: 1.2,
              marginTop: '3x',
            }}
          >
            {author}
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default Quote
