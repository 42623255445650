import React from 'react'
import { useTranslate } from 'react-polyglot'
import PageContainer from 'src/components/PageContainer/PageContainer'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import { useLocale } from 'src/lib/I18nContext'
import {
  BusinessNavigationItemName,
  LessonsNavigationItemName,
  NavigationItemName,
  SiteLocale,
} from 'src/types'

import slide1 from './assets/slide1@2x.png'
import slide2En from './assets/slide2-en@2x.png'
import slide2Other from './assets/slide2-other@2x.png'
import slide3En from './assets/slide3-en@2x.png'
import slide3De from './assets/slide3-de@2x.png'
import slide3Es from './assets/slide3-es@2x.png'
import TopFeatures from 'src/components/TopFeatures/TopFeatures'

const getAssets = (locale: SiteLocale) => {
  if (locale === SiteLocale.en) {
    return [slide1, slide2En, slide3En]
  }

  if (locale === SiteLocale.de) {
    return [slide1, slide2Other, slide3De]
  }

  if (locale === SiteLocale.es) {
    return [slide1, slide2Other, slide3Es]
  }

  return [slide1, slide2Other, slide3En]
}

export type Props = {
  sectionName?: NavigationItemName | LessonsNavigationItemName | BusinessNavigationItemName
}

const WhyChatterbug: React.FC<Props> = ({ sectionName }) => {
  const t = useTranslate()
  const locale = useLocale()
  const assets = getAssets(locale)

  const items = [
    {
      name: 'why.one-on-one',
      image: assets[0],
      heading: t('One on One Lessons'),
      description: t('why.one-on-one'),
    },
    {
      name: 'why.curriculum',
      image: assets[1],
      heading: t('Integrated Curriculum'),
      description: t('why.curriculum'),
    },
    {
      name: 'why.certification',
      image: assets[2],
      heading: t('Get Certified'),
      description: t('why.certification'),
    },
  ]

  return (
    <PageContainer>
      <SectionSeparator
        name={sectionName || NavigationItemName.whyChatterbug}
      />
      <TopFeatures items={items} />
    </PageContainer>
  )
}

export default WhyChatterbug
