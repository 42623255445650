import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'

import SiteBottom from 'src/sections/SiteBottom/SiteBottom'
import SignUpActions from 'src/sections/SignUpActions/SignUpActions'
import { LanguageCode, LanguageDropDownItem } from 'src/types'

export type Props = {
  selectedL2Code: LanguageCode | null
  onSelectedL2Change: (item: LanguageDropDownItem | null) => void
}

const LessonsBottom: React.FC<Props> = ({
  selectedL2Code,
  onSelectedL2Change,
}) => {
  const t = useTranslate()

  return (
    <SiteBottom
      headline={t(
        'Book your <strong>free</strong> trial lesson <em>today!</em>'
      )}
    >
      <Box minWidth={{ _: '100%', mobile: '80%', tablet: 0 }}>
        <SignUpActions
          ctaLabel={t('Book your trial lesson')}
          selectedL2Code={selectedL2Code}
          onSelectedL2Change={onSelectedL2Change}
        />
      </Box>
    </SiteBottom>
  )
}

export default LessonsBottom
