import React from 'react'
import { useTranslate } from 'react-polyglot'
import Header from 'src/components/Header/Header'
import { LanguageCode, NavigationItem } from 'src/types'
import {
  getNavigationItems,
  getLessonsNavigationItems,
} from 'src/lib/navigation'
import { useLocale } from 'src/lib/I18nContext'

export type Props = {
  l2Code?: LanguageCode | null
  canonicalPath: string
}

const LessonsHeader: React.FC<Props> = ({ l2Code, canonicalPath }) => {
  const t = useTranslate()
  const locale = useLocale()
  const ITEMS: NavigationItem[] = getNavigationItems(t, locale)

  return (
    <Header
      l2Code={l2Code}
      canonicalPath={canonicalPath}
      items={ITEMS}
      secondaryItemsGroup={{
        title: 'lessons',
        items: getLessonsNavigationItems(t),
      }}
    />
  )
}

export default LessonsHeader
