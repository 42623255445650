import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import Lessons from 'src/sections/Lessons/Lessons'
import { PageContext, TestimonialsJSON, TutorsJSON } from 'src/types'
import { useLocale } from 'src/lib/I18nContext'

type DataProps = {
  allTestimonialsJson: TestimonialsJSON
  allTutorsJson: TutorsJSON
}

const LessonsPage: React.FC<PageProps<DataProps, PageContext>> = ({
  pageContext,
  data,
}) => {
  const locale = useLocale()

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        pageContext={pageContext}
        title="Lessons"
        socialImage={`/assets/global/og-images/lessons/${locale}.jpg`}
      />
      <Lessons
        pageContext={pageContext}
        allTestimonials={data.allTestimonialsJson.nodes}
        allTutors={data.allTutorsJson.nodes}
      />
    </Layout>
  )
}

export default LessonsPage

export const query = graphql`
  {
    allTestimonialsJson {
      nodes {
        activeLocales
        l2Code
        image
        fullName
        cefrLevel
        hours
        id
        localizedTestimonial {
          locale
          text
        }
        orderByDesc
      }
    }
    allTutorsJson {
      nodes {
        activeLocales
        fullName
        image
        localizedBio {
          locale
          text
        }
        nativeLanguages
        fluentLanguages
        teachesLanguages
        orderByDesc
      }
    }
  }
`
