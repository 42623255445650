import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'

import { LanguageCode, LanguageDropDownItem, PageContext } from 'src/types'
import SiteHero from 'src/sections/SiteHero/SiteHero'
import SiteRating from 'src/sections/SiteRating/SiteRating'
import LessonsHeader from 'src/components/LessonsHeader/LessonsHeader'
import SignUpActions from 'src/sections/SignUpActions/SignUpActions'
import ProductLessonsPreview from 'src/sections/OrgIndex/subcomponents/ProductLessonsPreview/ProductLessonsPreview'

export type Props = {
  pageContext: PageContext
  selectedL2Code: LanguageCode | null
  onSelectedL2Change: (item: LanguageDropDownItem | null) => void
}

const LessonsHero: React.FC<Props> = ({
  pageContext,
  selectedL2Code,
  onSelectedL2Change,
}) => {
  const t = useTranslate()

  return (
    <SiteHero
      headline={t(
        'The <strong>best way</strong> to <strong>speak</strong> with <em>confidence</em>'
      )}
      sellingPoints={[
        t(
          '<strong>45 minute</strong> private online <strong>Live Lessons</strong>'
        ),
        t(
          '<strong>Structured</strong> classes with <strong>trained tutors</strong>'
        ),
        t(
          '<strong>24/7</strong> access to vocab, grammar & <strong>Live Lessons</strong>'
        ),
      ]}
      headerSection={
        <LessonsHeader
          l2Code={selectedL2Code}
          canonicalPath={pageContext.canonicalPath}
        />
      }
      infoBottomSection={
        <Box
          mb={{ _: '5x', mobile: 48 }}
          mt={{ _: '3x', mobile: '5x', tablet: 48 }}
          minWidth={{ _: '100%', mobile: '80%', tablet: 0 }}
        >
          <SignUpActions
            ctaLabel={t('Book your trial lesson')}
            selectedL2Code={selectedL2Code}
            onSelectedL2Change={onSelectedL2Change}
          />
        </Box>
      }
      previewSection={
        <Box
          maxWidth={{ _: 350, tablet: '100%' }}
          mx="auto"
          mt={{ _: '3x', mobile: '4x', tablet: 0 }}
        >
          <ProductLessonsPreview />
        </Box>
      }
      ratingSection={<SiteRating rating={4.9} />}
    />
  )
}

export default LessonsHero
