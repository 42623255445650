import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'

export type Props = {
  currentIndex: number
  max: number
  flexDirection?: { [key: string]: string } | string
  onClick?: (index: number) => void
}

const Dots: React.FC<Props> = ({
  currentIndex,
  max,
  flexDirection,
  onClick,
}) => {
  return (
    <Flex
      as="section"
      alignItems="center"
      display="inline-flex"
      flexDirection={flexDirection}
    >
      {[...Array(max)].map((_, index) => (
        <Box
          key={index}
          className="cb-dot"
          p="3px"
          onClick={onClick ? () => onClick(index) : undefined}
          sx={{ cursor: onClick ? 'pointer' : 'default' }}
        >
          <Box
            borderColor="baboon4"
            bg={index === currentIndex ? 'baboon4' : 'transparent'}
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: 'md',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
        </Box>
      ))}
    </Flex>
  )
}

export default Dots
