import React, { MouseEventHandler, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'
import PageContainer from 'src/components/PageContainer/PageContainer'
import Dots from 'src/components/Dots/Dots'
import Testimonial from 'src/components/Testimonial/Testimonial'
import ExtraLink from 'src/components/ExtraLink/ExtraLink'
import FadeIn from 'src/components/FadeIn/FadeIn'
import I18nContext from 'src/lib/I18nContext'
import { getLocalizedText } from 'src/lib/localization'
import { getNextIndex, sortDesc } from 'src/lib/utils'
import { TestimonialData, SiteLocale } from 'src/types'

export type Props = {
  allTestimonials: TestimonialData[]
}

const OurStudentsSay: React.FC<Props> = ({ allTestimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const t = useTranslate()

  return (
    <PageContainer>
      <I18nContext.Consumer>
        {(locale: SiteLocale) => {
          const items = sortDesc(
            allTestimonials.filter(
              (item) =>
                item.activeLocales.includes(locale) &&
                getLocalizedText(item.localizedTestimonial, locale)
            )
          )

          const handleNextClick: MouseEventHandler = (event) => {
            event.preventDefault()

            setCurrentIndex(getNextIndex(currentIndex, items.length))
          }

          if (!items.length) {
            return null
          }

          const hasNextItem = items.length > 1

          return (
            <Box>
              {hasNextItem && (
                <Flex justifyContent="center" mb="3x">
                  <Dots
                    currentIndex={currentIndex}
                    max={items.length}
                    onClick={setCurrentIndex}
                  />
                </Flex>
              )}
              <Box
                mx="auto"
                width={{
                  _: '100%',
                  laptop: `${(10 * 100) / 12}%`,
                }}
              >
                {items.map((item, index) => (
                  <FadeIn
                    as="article"
                    className="cb-testimonial"
                    key={`testimonial-${index}-${currentIndex}`}
                    // render all for crawlers
                    display={index === currentIndex ? 'block' : 'none'}
                    aria-hidden={index !== currentIndex}
                  >
                    <Testimonial
                      fullName={item.fullName}
                      image={item.image}
                      testimonial={getLocalizedText(
                        item.localizedTestimonial,
                        locale
                      )}
                      l2Code={item.l2Code}
                      cefrLevel={item.cefrLevel}
                      hours={item.hours}
                    >
                      <Flex justifyContent="flex-end" mt="3x">
                        {/* TODO: add Trustpilot */}
                        {hasNextItem && (
                          <ExtraLink
                            label={t('Next')}
                            href="#"
                            data-name="next-testimonial"
                            onClick={handleNextClick}
                          />
                        )}
                      </Flex>
                    </Testimonial>
                  </FadeIn>
                ))}
              </Box>
            </Box>
          )
        }}
      </I18nContext.Consumer>
    </PageContainer>
  )
}

export default OurStudentsSay
