import React from 'react'
import { useTranslate } from 'react-polyglot'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { useLocale } from 'src/lib/I18nContext'
import { getLessonsSignupUrl } from 'src/lib/app'
import { LanguageCode } from 'src/types'

const CTA_BUTTON_STYLES = {
  ...BUTTON_VARIANTS.primary,
  height: '44px',
}

export type Props = {
  label?: string
  l2Code?: LanguageCode | null
  size: 'normal' | 'large'
  width?: string | number | { [key: string]: string }
}

const ButtonSignUp: React.FC<Props> = ({ label, l2Code, size, width }) => {
  const locale = useLocale()
  const t = useTranslate()

  return (
    <Button
      variant={size === 'large' ? CTA_BUTTON_STYLES : BUTTON_VARIANTS.primary}
      width={width}
      label={label || t('Enroll now')}
      href={getLessonsSignupUrl(locale, l2Code)}
      className="cta-button-signup"
      data-l1={locale}
      data-l2={l2Code || 'unknown'}
    />
  )
}

export default ButtonSignUp
