import React from 'react'
import { Box, Flex, Grid } from '@chatterbug/aaron'

export const BUTTON_WIDTH = {
  _: '100%',
  tablet: 'auto',
}

export type Props = {
  message: string
  customButton: any
}

const ActionCard: React.FC<Props> = ({ message, customButton }) => {
  return (
    <Flex as="section" justifyContent="center">
      <Box
        minWidth={{
          _: '100%',
          tablet: `${(9 * 100) / 12}%`,
          tabletWide: `${(7 * 100) / 12}%`,
          laptop: `${(6 * 100) / 12}%`,
        }}
      >
        <Grid
          alignItems="center"
          bg="white100"
          borderRadius={{
            _: '24px',
            tablet: '40px',
          }}
          boxShadow="raised"
          p={{
            _: '16px 8px 8px',
            tablet: '8px',
          }}
          columns={{
            _: '1fr',
            tablet: '1fr auto',
          }}
          gap="2x"
        >
          <Box
            as="p"
            px="2x"
            variant="text.paragraph"
            fontSize={{
              mobile: 20,
            }}
            textAlign={{
              _: 'center',
              tablet: 'left',
            }}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
          {customButton}
        </Grid>
      </Box>
    </Flex>
  )
}

export default ActionCard
