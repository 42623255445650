import React, { MouseEventHandler, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { css, keyframes } from 'styled-components'
import { Box } from '@chatterbug/aaron'
import ExtraLink from 'src/components/ExtraLink/ExtraLink'
import { getNextIndex, getPrevIndex, getWidthFromIndex } from 'src/lib/utils'
import theme from 'src/lib/theme'

export type Props = {
  currentIndex: number
  max: number
  animated?: boolean
  onNext: (index: number, options?: { manual: boolean }) => void
}

const ANIM_TIMEOUT = 5000

const ProgressCard: React.FC<Props> = ({
  currentIndex,
  max,
  animated,
  onNext,
  children,
}) => {
  const prevIndex = getPrevIndex(currentIndex, max)
  const prevWidth =
    prevIndex === max - 1
      ? 0
      : getWidthFromIndex(getPrevIndex(currentIndex, max), max)
  const currentWidth = getWidthFromIndex(currentIndex, max)
  const t = useTranslate()
  const nextIndex = getNextIndex(currentIndex, max)

  const handleNextClick: MouseEventHandler = (event) => {
    event.preventDefault()

    onNext(nextIndex, { manual: true })
  }

  useEffect(() => {
    let timer: number | null = null
    if (animated) {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        onNext(nextIndex)
      }, ANIM_TIMEOUT)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [animated, nextIndex, onNext])

  const widthAnim = keyframes`
    0% {
      width: ${prevWidth};
    }
    100% {
      width: ${currentWidth};
    }
  `

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: theme.namedColors.neutralBackground,
        boxShadow: 'overlay',
        borderRadius: '4px',
        padding: '40px 32px 32px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '4px',
          background: 'rgba(234,61,109,0.1)',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: prevWidth,
            height: '100%',
            backgroundColor: 'pink51',
            borderRadius: '0 8px 8px 0',
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={css`
            animation: ${animated ? ANIM_TIMEOUT / 1000 : '0.3'}s ${widthAnim}
              linear forwards;
          `}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          position: 'absolute',
          top: '1px',
          left: 0,
          width: '100%',
        }}
      >
        {[...Array(max - 1)].map((_, index) => (
          <Box
            key={index}
            bg="white100"
            width={2}
            height={2}
            borderRadius="100%"
          />
        ))}
      </Box>
      {children}
      <Box mt="2x">
        <ExtraLink
          label={t('Next')}
          href="#"
          onClick={handleNextClick}
          data-name="next-method"
        />
      </Box>
    </Box>
  )
}

export default ProgressCard
