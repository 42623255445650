import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box } from '@chatterbug/aaron'
import { CB_LINKS } from 'src/lib/app'

import reviews from './assets/reviews.svg'

export type Props = {
  rating: number
}

const SiteRating: React.FC<Props> = ({ rating }) => {
  const t = useTranslate()

  return (
    <Box
      as="a"
      href={CB_LINKS.googleReview}
      target="_blank"
      rel="noopener nofollow"
      variant="text.paragraph"
      sx={{
        display: 'inline-block',
        background: `url(${reviews}) no-repeat`,
        backgroundPosition: 'top left',
        minHeight: '41px',
        minWidth: '138px',
        textAlign: 'left',
        color: 'black0',
        lineHeight: 1.2,
        fontSize: '14px',
        paddingLeft: '37px',
        textDecoration: 'none',
        ':hover, :active, :focus': {
          textDecoration: 'none',
          color: 'black0',
        },
      }}
    >
      <Box pt="2px">{t('Google Rating')}</Box>
      <Box pt="2px" color="black0" aria-label={`${rating} out of 5`}>
        {rating}
      </Box>
    </Box>
  )
}

export default SiteRating
