import React from 'react'
import { Box, Grid } from '@chatterbug/aaron'
import DropdownLearningLanguage from 'src/sections/DropdownLearningLanguage/DropdownLearningLanguage'
import ButtonSignUp from 'src/sections/ButtonSignUp/ButtonSignUp'
import { LanguageCode, LanguageDropDownItem } from 'src/types'

export type Props = {
  ctaLabel?: string
  selectedL2Code: LanguageCode | null
  onSelectedL2Change: (item: LanguageDropDownItem | null) => void
}

const SignUpActions: React.FC<Props> = ({
  ctaLabel,
  selectedL2Code,
  onSelectedL2Change,
}) => {
  return (
    <Grid
      className="cb-signup-actions"
      alignItems="center"
      gap="2x"
      columns={{
        _: '1fr',
        tablet: 'auto auto',
      }}
    >
      <Box>
        <DropdownLearningLanguage
          value={selectedL2Code}
          onChange={onSelectedL2Change}
        />
      </Box>
      <Box>
        <ButtonSignUp
          label={ctaLabel}
          l2Code={selectedL2Code}
          size="large"
          width={{
            _: '100%',
            tablet: 'auto',
          }}
        />
      </Box>
    </Grid>
  )
}

export default SignUpActions
