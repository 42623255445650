import React, { useState } from 'react'
import { Box } from '@chatterbug/aaron'
import SectionSeparator from 'src/sections/SectionSeparator/SectionSeparator'
import WhyChatterbug from 'src/sections/WhyChatterbug/WhyChatterbug'
import OurTutors from 'src/sections/OurTutors/OurTutors'
import OurMethod from 'src/sections/Lessons/subcomponents/OurMethod/OurMethod'
import SeeInAction from 'src/sections/Lessons/subcomponents/SeeInAction/SeeInAction'
import OurStudentsSay from 'src/sections/Lessons/subcomponents/OurStudentsSay/OurStudentsSay'
import LessonsHero from 'src/sections/Lessons//subcomponents/LessonsHero/LessonsHero'
import LessonsBottom from 'src/sections/Lessons/subcomponents/LessonsBottom/LessonsBottom'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import { useLocale } from 'src/lib/I18nContext'
import { getInitiallySelectedL2Code } from 'src/lib/localization'
import {
  LanguageCode,
  LanguageDropDownItem,
  LessonsNavigationItemName,
  PageContext,
  TestimonialData,
  TutorData,
} from 'src/types'
import background from './assets/background.svg'
import { LessonsThemeProvider } from 'src/lib/pageThemeContext'

export type Props = {
  pageContext: PageContext
  allTestimonials: TestimonialData[]
  allTutors: TutorData[]
}

const Lessons: React.FC<Props> = ({
  pageContext,
  allTestimonials,
  allTutors,
}) => {
  const locale = useLocale()
  const initialL2Code = getInitiallySelectedL2Code(locale)
  const [selectedL2Code, setSelectedL2Code] = useState<LanguageCode | null>(
    initialL2Code
  )

  const handleSelectedL2Change = (item: LanguageDropDownItem | null) =>
    setSelectedL2Code(item?.value || null)

  return (
    <LessonsThemeProvider>
      <LessonsHero
        pageContext={pageContext}
        selectedL2Code={selectedL2Code}
        onSelectedL2Change={handleSelectedL2Change}
      />
      <Box
        as="section"
        id={LessonsNavigationItemName.lessonsFeatures}
        mt={-32}
        position="relative"
        sx={{ zIndex: 2 }}
      >
        <FullWidthBackground image={background} width={1439} height={741}>
          <Box pt="4x">
            <WhyChatterbug
              sectionName={LessonsNavigationItemName.lessonsFeatures}
            />
          </Box>
        </FullWidthBackground>
      </Box>
      <Box as="section" id={LessonsNavigationItemName.method} mt={88}>
        <SectionSeparator name={LessonsNavigationItemName.method} />
        <Box mt="3x">
          <OurMethod />
        </Box>
      </Box>
      <Box
        as="section"
        id={LessonsNavigationItemName.inAction}
        mt={{
          _: 36,
          tablet: 56,
        }}
      >
        <SeeInAction l2Code={selectedL2Code} />
      </Box>
      <Box as="section" id={LessonsNavigationItemName.students} mt={88}>
        <SectionSeparator name={LessonsNavigationItemName.students} />
        <Box mt={56}>
          <OurStudentsSay allTestimonials={allTestimonials} />
        </Box>
      </Box>
      <Box
        as="section"
        id={LessonsNavigationItemName.tutors}
        mt={{
          _: 64,
          laptop: 88,
        }}
      >
        <SectionSeparator name={LessonsNavigationItemName.tutors} />
        <Box mt={64}>
          <OurTutors allTutors={allTutors} />
        </Box>
      </Box>
      <Box
        as="section"
        mt={{
          _: 64,
          laptop: 88,
        }}
      >
        <LessonsBottom
          selectedL2Code={selectedL2Code}
          onSelectedL2Change={handleSelectedL2Change}
        />
      </Box>
    </LessonsThemeProvider>
  )
}

export default Lessons
