import React from 'react'
import { useTranslate } from 'react-polyglot'
import Dropdown from 'src/components/Dropdown/Dropdown'
import Flag from 'src/components/Flag/Flag'
import { useLocale } from 'src/lib/I18nContext'
import { LanguageCode, LanguageDropDownItem } from 'src/types'

export type Props = {
  value: LanguageCode | null
  onChange: (item: LanguageDropDownItem | null) => void
}

const DropdownLearningLanguage: React.FC<Props> = ({ value, onChange }) => {
  const locale = useLocale()
  const t = useTranslate()

  let languages: LanguageDropDownItem[] = [
    {
      value: LanguageCode.en,
      label: t('Learn %{l2Name}', { l2Name: t('English') }),
      icon: <Flag code={LanguageCode.en} />,
    },
    {
      value: LanguageCode.de,
      label: t('Learn %{l2Name}', { l2Name: t('German') }),
      icon: <Flag code={LanguageCode.de} />,
    },
    {
      value: LanguageCode.es,
      label: t('Learn %{l2Name}', { l2Name: t('Spanish') }),
      icon: <Flag code={LanguageCode.es} />,
    },
    {
      value: LanguageCode.fr,
      label: t('Learn %{l2Name}', { l2Name: t('French') }),
      icon: <Flag code={LanguageCode.fr} />,
    },
  ]

  const currentLanguageIndex = languages.findIndex(
    (language) => String(language.value) === String(locale)
  )

  if (currentLanguageIndex >= 0) {
    languages = [
      ...languages.filter((_, index) => index !== currentLanguageIndex),
      languages[currentLanguageIndex],
    ]
  }

  return (
    <Dropdown
      className="dropdown-learning-language"
      size="large"
      items={languages}
      placeholder={t('Choose Language')}
      icon={<Flag code="other" />}
      value={value}
      onChange={onChange}
    />
  )
}

export default DropdownLearningLanguage
